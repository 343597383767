<template>
    <div
        :class="{
            [$style['content']]: true,
            [$style['content_centered']]: centered,
            [$style['content_inverted']]: inverted
        }"
    >
        <div :class="$style['content__container']">
            <h2
                :class="[$style['content__title'], 'sw-title']"
                v-html="title"
            />

            <template v-for="(item, index) in content">
                <div
                    :key="'main-' + index"
                    :class="[$style['content__text'], 'sw-text']"
                    v-html="item.text"
                />

                <div
                    :key="'additional-' + index"
                    :class="[$style['content__text'], 'sw-text']"
                    v-html="item.additionalText"
                />
            </template>

            <div>
                <SocialNetworkLink
                    v-for="(item, index) in social"
                    :key="index"
                    :class="$style['content__social-network-link']"
                    :icon="item.key"
                    :url="item.url"
                />
            </div>
        </div>
    </div>
</template>

<script>
import SocialNetworkLink from '@@/website/components/common/SocialNetworkLink'

export default {
  name: 'ContactsAndText',

  components: {
    SocialNetworkLink
  },

  props: {
    title: {
      type: String,
      default: ''
    },

    content: {
      type: Array,
      default: () => []
    },

    social: {
      type: Array,
      default: () => []
    },

    centered: {
      type: Boolean,
      default: false
    },

    inverted: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="less" module>
.content {
  display: flex;
  box-sizing: border-box;
  margin-top: auto;
  margin-bottom: auto;

  &_centered {
    align-self: center;
    text-align: center;
  }

  &_inverted {
    color: #FFFFFF;
  }

  &__container {
    max-width: 320px;
    color: inherit;
  }

  &__title {
    width: 100%;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    color: inherit;
    margin-top: 0;
    margin-bottom: 24px;
  }

  &__text {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: inherit;
    margin-bottom: 16px;
    &:nth-child(2),
    &:nth-last-child(2) {
      margin-bottom: 24px;
    }
  }

  &__social-network-link {
    margin-right: 20px;
    margin-bottom: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
